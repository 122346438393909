import React, { useState } from 'react'

import Image from 'gatsby-image'

import styles from './style.module.scss'

import { Link } from 'gatsby'

import SocialBar from '../social-bar'

import cn from 'classnames'

import DefaultThumbnail from '../single-project/default-thumbnail'

import { get } from 'lodash-es'

function getPosts(data) {
    const edges = get(data, 'allWordpressPost.edges') || []

    return ([...edges] || [])
        .filter(({ node: post }) => !post.acf.is_popular_today)
        .sort(({ node: p1 }, { node: p2 }) => {
            if (p1.acf.sort_in_mag === p2.acf.sort_in_mag) return 0

            return +p1.acf.sort_in_mag < +p2.acf.sort_in_mag ? -1 : 1
        })
}

export default ({ data }) => {
    const mag = get(data, 'allWordpressWpPropertyMag.edges[0].node') || {}
    const project = get(data, 'allWordpressWpProject.edges[0].node') || {}
    const [posts] = useState(getPosts(data))

    let popularToday = data.allWordpressPost.edges.find(
        ({ node: post }) => post.is_popular_today
    )

    popularToday =
        get(popularToday, 'node') ||
        get(data, 'allWordpressPost.edges[0].node') ||
        {}
    return (
        <div className={styles.container}>
            <h1>Property Mag {mag.name}</h1>
            <div className={styles.body}>
                {posts.map((p, i) => (
                    <div
                        key={p.node.title}
                        className={cn(
                            'row',
                            i % 2 !== 0 && 'reverse',
                            styles.post
                        )}
                    >
                        <div className="col-xs-12 col-md-6">
                            <h3
                                className={styles.postTitle}
                                dangerouslySetInnerHTML={{
                                    __html: p.node.title
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: p.node.excerpt
                                }}
                            />
                            <Link
                                to={'/blog/' + p.node.slug}
                                className={styles.readMore}
                            >
                                Read more
                            </Link>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            {get(
                                p,
                                'node.featured_media.localFile.childImageSharp.fluid'
                            ) ? (
                                <Image
                                    fluid={get(
                                        p,
                                        'node.featured_media.localFile.childImageSharp.fluid'
                                    )}
                                    className={styles.image}
                                />
                            ) : (
                                <DefaultThumbnail />
                            )}
                        </div>
                    </div>
                ))}
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <h2 className={styles.featuredProjectsTitle}>
                            Popular today
                        </h2>
                        <div className={styles.project}>
                            {get(
                                popularToday,
                                'featured_media.localFile.childImageSharp.fluid'
                            ) ? (
                                <Image
                                    fluid={get(
                                        popularToday,
                                        'featured_media.localFile.childImageSharp.fluid'
                                    )}
                                    className={styles.image}
                                />
                            ) : (
                                <DefaultThumbnail />
                            )}

                            <h3
                                className={styles.projectTitle}
                                dangerouslySetInnerHTML={{
                                    __html: popularToday.title
                                }}
                            />
                            <div
                                className={styles.projectExcerpt}
                                dangerouslySetInnerHTML={{
                                    __html: popularToday.excerpt
                                }}
                            />
                            <Link to={'/blog/' + (popularToday.slug || '')}>
                                Know more
                            </Link>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <h2 className={styles.featuredProjectsTitle}>
                            Featured project
                        </h2>
                        <div className={styles.project}>
                            {get(
                                project,
                                'featured_media.localFile.childImageSharp.fluid'
                            ) ? (
                                <Image
                                    fluid={get(
                                        project,
                                        'featured_media.localFile.childImageSharp.fluid'
                                    )}
                                    className={styles.image}
                                />
                            ) : (
                                <DefaultThumbnail />
                            )}

                            <h3
                                className={styles.projectTitle}
                                dangerouslySetInnerHTML={{
                                    __html: project.title
                                }}
                            />
                            <div
                                className={styles.projectExcerpt}
                                dangerouslySetInnerHTML={{
                                    __html: project.excerpt
                                }}
                            />
                            <Link to={'/project/' + project.slug}>
                                Know more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.copy}>
                    Published By: Propertyeportal Media Team
                </div>
                <SocialBar inline />
            </div>
        </div>
    )
}
