import React from 'react'

import { graphql } from 'gatsby'

import Layout from '../components/layout'

import SEO from '../components/seo'

import SinglePropertyMag from '../components/single-property-mag'

export default ({ data }) => {
    const mag = data.allWordpressWpPropertyMag.edges[0].node

    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Property Mag',
                    link: '/property-mag'
                },
                {
                    title: mag.name
                }
            ]}
            marketTicker
        >
            <SEO
                title={mag.acf.meta_title}
                description={mag.acf.meta_description}
            />
            <div className="wrapper">
                <SinglePropertyMag data={data} />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!, $id: [Int!]) {
        allWordpressWpPropertyMag(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    name
                    slug
                    acf {
                        meta_title
                        meta_description
                    }
                }
            }
        }

        allWordpressPost(filter: { property_mag: { in: $id } }) {
            edges {
                node {
                    title
                    slug
                    excerpt
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    acf {
                        sort_in_mag
                        is_popular_today
                    }
                }
            }
        }

        allWordpressWpProject(filter: { property_mag: { in: $id } }) {
            edges {
                node {
                    title
                    content
                    excerpt
                    slug
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    acf {
                        end_user_grade
                        investor_grade
                    }
                }
            }
        }
    }
`
